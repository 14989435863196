<template>
  <div class="particles-wrapper">
    <div id="particles-js"></div>
  </div>
</template>

<script>
import GlobalComputed from "@/helpers/global-computed";
// import GlobalMethods from '@/helpers/global-methods'

export default {
  computed: {
    ...GlobalComputed,
  },
  mounted() {
    let count = this.pcOnly ? 100 : 35,
      lineLinkedDistance = this.pcOnly ? 200 : 100;
    window.particlesJS("particles-js", {
      particles: {
        number: { value: count, density: { enable: false, value_area: 1000 } },
        color: { value: "#021638" },
        shape: {
          type: "circle",
          stroke: { width: 0, color: "#015486" },
          polygon: { nb_sides: 5 },
          image: { src: "img/github.svg", width: 100, height: 100 },
        },
        opacity: {
          value: 1,
          random: true,
          anim: { enable: true, speed: 3, opacity_min: 0.2, sync: false },
        },
        size: {
          value: 3,
          random: false,
          anim: { enable: true, speed: 4, size_min: 2, sync: false },
        },
        line_linked: {
          enable: true,
          distance: lineLinkedDistance,
          color: "#015486",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 5,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: true,
          attract: { enable: true, rotateX: 1000, rotateY: 1500 },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: { enable: false, mode: "repulse" },
          onclick: { enable: false, mode: "push" },
          resize: true,
        },
        modes: {
          grab: { distance: 400, line_linked: { opacity: 1 } },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 2,
          },
          repulse: { distance: 200, duration: 0.4 },
          push: { particles_nb: 4 },
          remove: { particles_nb: 2 },
        },
      },
      retina_detect: true,
    });
  },
};
</script>

<style lang="scss" scoped>
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
